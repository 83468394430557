<template>
  <div
    data-component-name="PartnersMarquee"
    :class="{ invert }"
  >
    <NuxtImg
      v-for="({ attributes }, index) of images"
      v-once
      :key="index"
      v-bind="attributes"
      :ref="el => imageRefHandler(el, index)"
    />
  </div>
</template>

<!--
              _________________________________
             |                                 |
   ______    |   _______   _______   _______   |    _________     ______
  |      |   |  |       | |       | |       |  |   |         |   |      |
  | DEAD |   |  | ALIVE | | ALIVE | | ALIVE |  |   | PHANTOM |   | DEAD |
  |______|   |  |_______| |_______| |_______|  |   |_________|   |______|
             |                                 |
             |_________________________________|

-->

<script setup lang="ts">
// utils
import isNumber from '~/utils/isNumber';
import throttleRAF from '~/utils/throttleRAF';

const GAP = 16 * 2;

enum STATUS {
  DEAD = 'DEAD',
  ALIVE = 'ALIVE',
  PHANTOM = 'PHANTOM',
}

withDefaults(
  defineProps<{
    background?: '#F8F9FA' | 'transparent'
    invert?: boolean
  }>(),
  {
    background: '#F8F9FA',
    invert: false,
  },
);

type Image = {
  el?: HTMLImageElement
  offset?: number
  rafId?: number
  status?: STATUS

  attributes: {
    src: string
    width: number
    height: number
    alt:  string
  }
}

const images: Image[] = [
  {
    attributes: {
      src: '/redesign/images/partners/Adistec_bw.webp',
      alt: 'Adistec logo',
      width: 102,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Anm_bw.webp',
      alt: 'Anm logo',
      width: 129,
      height: 41,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Arrow_bw.webp',
      alt: 'Arrow logo',
      width: 137,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/BackBlaze_bw.webp',
      alt: 'BlackBlaze logo',
      width: 192,
      height: 45,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/BlueAlly_bw.webp',
      alt: 'BlueAlly logo',
      width: 125,
      height: 71,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/CDW_bw.webp',
      alt: 'CDW logo',
      width: 131,
      height: 66,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Compunet_bw.webp',
      alt: '',
      width: 112,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Connection_bw.webp',
      alt: 'Connection logo',
      width: 105,
      height: 41,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/ConvergeOne_bw.webp',
      alt: 'ConvergeOne logo',
      width: 184,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Copaco_bw.webp',
      alt: 'Copaco logo',
      width: 113,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Datta_bw.webp',
      alt: 'Datta logo',
      width: 133,
      height: 45,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Exertise_bw.webp',
      alt: 'Exertis logo',
      width: 124,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/ExertisCybersecurity_bw.webp',
      alt: 'Exertis Cybersecurity logo',
      width: 240,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/GHATechnologies_bw.webp',
      alt: 'GHATechnologies logo',
      width: 168,
      height: 54,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Ingram_bw.webp',
      alt: 'IngramMicro logo',
      width: 173,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/IntegraOne_bw.webp',
      alt: 'IntegraOne logo',
      width: 133,
      height: 47,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/LevelSolutionsGroup_bw.webp',
      alt: 'LevelSolutionsGroup logo',
      width: 145,
      height: 57,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Lillis_bw.webp',
      alt: 'The Lillis Technology Group logo',
      width: 120,
      height: 42,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Logicalis_bw.webp',
      alt: 'Logicalis logo',
      width: 316,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/MicroAge_bw.webp',
      alt: 'MicroAge logo',
      width: 141,
      height: 61,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Mirazon_bw.webp',
      alt: 'Mirazon logo',
      width: 140,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Pedab_bw.webp',
      alt: 'Pedab logo',
      width: 67,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Presidio_bw.webp',
      alt: 'Presidio logo',
      width: 207,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Prodatix_bw.webp',
      alt: 'Prodatix logo',
      width: 111,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Sentinel_bw.webp',
      alt: 'Sentinel logo',
      width: 157,
      height: 39,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Softchoise_bw.webp',
      alt: 'Softchoise logo',
      width: 132,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Synnex_bw.webp',
      alt: 'TD Synnex logo',
      width: 146,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/V-valey_bw.webp',
      alt: 'V-Valey logo',
      width: 107,
      height: 24,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Verinext_bw.webp',
      alt: 'Verinext logo',
      width: 135,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/VLCM_bw.webp',
      alt: 'VLCM logo',
      width: 106,
      height: 36,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Waident_bw.webp',
      alt: 'Waident logo',
      width: 106,
      height: 36,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/Wasabi_bw.webp',
      alt: 'Wasabi logo',
      width: 111,
      height: 28,
    },
  },
  {
    attributes: {
      src: '/redesign/images/partners/WorldWideTechnologies_bw.webp',
      alt: 'WorldWideTechnologies logo',
      width: 172,
      height: 36,
    },
  },
];

const imageRefHandler = (
  value: Element | ComponentPublicInstance | null,
  index: number,
) => {
  if (!value || value instanceof Element || images[index].el) return;

  images[index].el = value.$el;
};

const calcImagesOffsets = () => {
  images.forEach((image, index, self) => {
    let offset = 0;
    const prevImage = self[index - 1];

    if (isNumber(prevImage?.offset)) {
      offset = prevImage.offset + prevImage.attributes.width + GAP;
    }

    image.offset = offset;
  });
};

const canManipulateImage = (image: Image = {}): image is Required<Image> => {
  return isNumber(image.offset) && (image.el instanceof HTMLImageElement);
};

const destroyImage = (image: Image) => {
  if (!canManipulateImage(image)) return;

  image.el.remove();
  image.status = STATUS.DEAD;
};

const updateImageOffset = (image: Image, offset: number) => {
  if (!canManipulateImage(image)) return;

  image.offset = offset;
  image.el.style.left = `${offset}px`;
};

const getNearbyImages = () => images
  .reduce<Record<'phantom' | 'dead', Image | undefined>>(
    (acc, image) => {
      switch (image.status) {
        case STATUS.PHANTOM: {
          acc.phantom = image;
          break;
        }

        case STATUS.DEAD: {
          if (!acc.dead) {
            acc.dead = image;
          }

          break;
        }
      }

      return acc;
    },
    {},
  );

const shiftPhantomImage = () => {
  const { phantom, dead } = getNearbyImages();

  if (
    !canManipulateImage(phantom) ||
    !canManipulateImage(dead)
  ) return;

  phantom.status = STATUS.ALIVE;
  dead.status = STATUS.PHANTOM;

  updateImageOffset(dead, phantom.offset + phantom.attributes.width + GAP);

  phantom.el.after(dead.el);

  return dead;
};

const updateImagesVisibility = () => {
  images.forEach((image, index, self) => {
    if (!canManipulateImage(image)) return;

    if ((image.offset + image.attributes.width) < window.innerWidth) {
      image.status = STATUS.ALIVE;
    } else {
      const prevImage = self[index - 1];

      if (!prevImage) return;
      if (prevImage.status === STATUS.ALIVE) {
        image.status = STATUS.PHANTOM;
      } else {
        destroyImage(image);
      }
    }
  });
};

const changeImagesPositioning = () => {
  images.forEach(image => {
    if (!canManipulateImage(image)) return;

    const { el, offset } = image;

    el.style.left = offset + 'px';
    el.style.position = 'absolute';
  });
};

const startCrawling = (image: Image) => {
  const callback: Parameters<typeof throttleRAF>[0] = rafId => {
    image.rafId = rafId;

    if (!canManipulateImage(image)) return;

    updateImageOffset(image, image.offset - 1);

    if (
      image.status === STATUS.PHANTOM &&
      (image.offset + image.attributes.width) < window.innerWidth
    ) {
      const phantomImage = shiftPhantomImage();

      if (phantomImage) {
        startCrawling(phantomImage);
      }
    }

    if ((image.offset + image.attributes.width) < 0) {
      stopCrawling();
      cancelAnimationFrame(image.rafId);
    }
  };

  image.rafId = throttleRAF(callback);
};

const stopCrawling = () => {
  const image = images.shift();
  images.push(image);

  if (canManipulateImage(image)) {
    destroyImage(image);
  }
};

const launchMarquee = () => {
  images.forEach(image => {
    if (image.status !== STATUS.DEAD) {
      startCrawling(image);
    }
  });
};

calcImagesOffsets();

onMounted(() => {
  updateImagesVisibility();
  changeImagesPositioning();
  launchMarquee();
});

onUnmounted(() => {
  images.forEach(image => {
    if (isNumber(image.rafId)) {
      cancelAnimationFrame(image.rafId);
    }
  });
});
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/functions/token";

[data-component-name="PartnersMarquee"] {
  @include flex-center-start;
  gap: 0 2rem;
  height: 7.75rem;
  background-color: v-bind(background);

  position: relative;
  overflow-x: hidden;

  &.invert {
    img {
      filter: invert(1) brightness(2);
    }
  }
}
</style>
